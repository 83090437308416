import { Injectable } from '@angular/core';
import {CacheRegistrationService} from './cache-registration.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap, shareReplay} from 'rxjs/operators';
import {UtilService} from '../util.service';

@Injectable({
  providedIn: 'root'
})
/** https://medium.com/@vikeshm/data-caching-angular-http-interceptor-2d87f95e2340 */
export class UriCachingInterceptor implements HttpInterceptor {
  private cachedData = new Map<string, any>();
  private cachedTime = new Map<string, Date>();

  constructor(private cacheRegistrationService: CacheRegistrationService,
              private utilService: UtilService) {
  }

  // Limpia la caché, se debe llamar a esta función después de cada login y cambio de token
  public clearCache(): void {
    this.cachedData.clear();
  }

  public intercept(httpRequest: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    // Don't cache if
    // 1. It's not a GET request
    // 2. If URI is not supposed to be cached
    if (httpRequest.method !== 'GET') {
      return handler.handle(httpRequest);
    }
    const minutosCache = this.cacheRegistrationService.isCacheable(httpRequest.url);
    if ( minutosCache === null ) {
      return handler.handle(httpRequest);
    }

    // Also leave scope of resetting already cached data for a URI
    if (httpRequest.headers.get('reset-cache')) {
      this.cachedData.delete(httpRequest.urlWithParams);
      console.log( 'Borramos caché:' + httpRequest.url);
    }

    // Checked if there is cached data for this URI
    // Añado que la respuesta sea correcta para no cachear errores.
    const lastResponse = this.cachedData.get(httpRequest.urlWithParams);
    if (lastResponse && lastResponse.status === 200) {
      let caducada = false;
      if ( minutosCache !== 0 ) {
        const lastTime = this.cachedTime.get(httpRequest.urlWithParams);
        const minutosTranscurridos = this.utilService.minutosTranscurridos( lastTime );

        if ( lastTime === null || minutosTranscurridos > minutosCache) {
          // Si no hay valor o el valor guardado ha caducado
          caducada = true;
          console.log( 'CADUCADA A REFRESCAR ' +  httpRequest.url);
        }
      }

      // In case of parallel requests to same URI,
      // return the request already in progress
      // otherwise return the last cached data
      if ( !caducada ) {
        return (lastResponse instanceof Observable) ? lastResponse : of(lastResponse.clone());
      }
    }

    // If the request of going through for first time
    // then let the request proceed and cache the response
    // const requestHandle = handler.handle(httpRequest).do((stateEvent) => {
    const requestHandle = handler.handle(httpRequest).pipe(tap((stateEvent) => {
      if (stateEvent instanceof HttpResponse) {
        this.cachedData.set(
          httpRequest.urlWithParams,
          stateEvent.clone()
        );
      // } else if (stateEvent instanceof HttpErrorResponse) {
      //     console.error('Error en la respuesta:", stateEvent);
      }

    }), shareReplay(1));

    // Meanwhile cache the request Observable to handle parallel request
    this.cachedData.set(httpRequest.urlWithParams, requestHandle);
    if ( minutosCache > 0 ) {
      this.cachedTime.set( httpRequest.urlWithParams, new Date());
    }

    return requestHandle;
  }
}

