import { Component, OnInit } from '@angular/core';
import {LoginService} from '../../shared/login.service';
import {UtilService} from '../../services/util.service';
import {Router} from '@angular/router';
import {RefrescoService} from '../../services/refresco.service';
import {IconosMenu} from '../../shared/constantes';
import {environment} from '../../../environments/environment';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {
  constructor(private router: Router,
              public loginService: LoginService,
              public utilService: UtilService,
              private refrescoService: RefrescoService,
              private snackBar: MatSnackBar) { }

  numMsgPdte       = 0;
  numOfertaPdte    = 0;
  logged = false;

  iconosMenu = IconosMenu;
  staticWebUrl = environment.staticWebUrl;
  theme = '';

  ngOnInit(): void {
    console.log('init TitleComponent');
    this.logged = this.loginService.isLogged();
    if (this.logged) {
      this.refrescoService.totalMsgPendientes.subscribe(totalMsgPendientes => {

        console.log('title nuevo mensaje ' + totalMsgPendientes );
        if ( this.numMsgPdte !== totalMsgPendientes) {
          console.log('title nuevo mensaje cambio el total' + totalMsgPendientes );
          this.numMsgPdte = totalMsgPendientes;
        }
        // if ( totalMsgPendientes > 0) {
        //   this.snackBar.open('Nuevo mensaje recibido', 'cerrar', {duration: 5000});
        // }
      });
      this.refrescoService.totalOfertasAsuntosPendientes.subscribe(ofertasPendientes => {
        console.log('title novedades cambios ' + ofertasPendientes );
        if ( this.numOfertaPdte !== ofertasPendientes.length) {
          this.numOfertaPdte = ofertasPendientes.length;
        }
        // if ( ofertasPendientes.length > 0 ) {
        //   this.snackBar.open('Novedades en tus intercambios', 'cerrar', {duration: 5000});
        // }
      });
    }
    this.theme = localStorage.getItem('theme');
    if ( this.theme === undefined || this.theme === null ) {
      this.theme = 'light-theme';
      localStorage.setItem('theme', this.theme );
    }
  }

  onLogout(): void {
    this.loginService.logout();
    // this.router.navigateByUrl('login');
    window.location.href = environment.staticWebUrl + 'usuario/logout';
  }

  cambiarTema(): void {
    (window as any).changeTheme();
    this.theme = localStorage.getItem('theme');
  }
}
