<div class="balance" (click)="onClick()" *ngIf="socio > 1">
  <div class="balance-izda">
    <progress max="100" value="{{ratioPides*100/(ratioOfreces+0.0001)}}"><sup><i>{{ratioPides}}</i></sup></progress>
  </div>
  <div class="balance-central" matTooltip="Balanza orientativa. Pulsa para más info.">
    &nbsp;<div>{{ratioPides}}</div> <mat-icon>balance</mat-icon> <div>{{ratioOfreces}}</div>&nbsp;
  </div>
  <div class="balance-dcha">
    <progress max="100" value="{{ratioPides === 0 ? 0 : ratioOfreces*100/(ratioPides+0.0001)}}"></progress>
  </div>
</div>

