import {Component, Input, OnInit} from '@angular/core';
import {LoginService} from '../../../shared/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-oferta-balance',
  templateUrl: './oferta-balance.component.html',
  styleUrls: ['./oferta-balance.component.css']
})
export class OfertaBalanceComponent implements OnInit {
  @Input() ratioPides: number;
  @Input() ratioOfreces: number;

  socio: number;
  valorPides = 0;
  valorOfreces = 0;


  constructor(public loginService: LoginService) { }

  ngOnInit(): void {
    this.socio = this.loginService.getSocio();

    if ( this.socio > 1) {
      this.valorPides = this.ratioPides * 100 / (this.ratioOfreces + 0.0001);
      this.valorOfreces = this.ratioPides === 0 ? 0 : this.ratioOfreces * 100 / (this.ratioPides + 0.0001);
    } else {
      this.valorPides = 0;
      this.valorOfreces = 0;
    }
  }

  onClick(): void {
    Swal.fire('Balanza orientativa', 'AVISO: Debes ignorar la balanza, si los cromos son de colecciones diferentes.<br>' +
      'El valor de un cromo es: n.º de coleccionistas que lo tiene como falta, entre n.º de coleccionistas que lo tienen repetido.<br>' +
      'Cuanto más grande es un valor, mayor es la dificultad del cromo y tiene una demanda mayor.'
      , 'info');
  }
}
