import {EventEmitter, Injectable, Output} from '@angular/core';
import {LoginService} from '../shared/login.service';
import {UtilService} from './util.service';

@Injectable({
  providedIn: 'root'
})
export class RefrescoService {

  @Output() totalMsgPendientes: EventEmitter<number> = new EventEmitter();
  @Output() totalOfertasAsuntosPendientes: EventEmitter<number[]> = new EventEmitter();

  interval: any;
  tiempoMaximo  = 150000;
  tiempoMinimo  =  20000;
  incremento    =  10000;
  timeoutMsg    = this.tiempoMinimo;
  timeoutOferta = this.tiempoMinimo;

  msgPdte        = null;
  arrOfertasPdte = [];

  constructor(private loginService: LoginService,
              private utilService: UtilService) {
    // console.log('init RefrescoService');
    this.timeoutMensajes(5000);
    this.timeoutOfertas( 5000);
  }

  timeoutOfertas( milliseconds: number): void  {
    this.interval = setTimeout(() => {
      // console.log('Timeout Ofertas');
      if ( this.loginService.isLogged()) {
        this.loginService.refrescaOfertas().subscribe(nuevoArrOfertas => {
          const hayCambios = !this.utilService.arrayEqual(this.arrOfertasPdte, nuevoArrOfertas);
          // const hayCambios = this.arrOfertasPdte.length !== nuevoArrOfertas.length;
          if ( hayCambios) {
            console.log('EMIT ----> Ofertas Asuntos pendientes ' + nuevoArrOfertas);
            // Para que no se mueva el scroll arriba cada vez que cambia una oferta
            // En lugar de emitir el array de ofertas cambiadas, emitimos un array con 1 o sin elementos.
            this.totalOfertasAsuntosPendientes.emit(nuevoArrOfertas.length > 0 ? [1] : []);
          }
          this.timeoutOferta = this.tiempoSiguienteLlamada( hayCambios, this.timeoutMsg );
          this.timeoutOfertas( this.timeoutMsg );
          this.arrOfertasPdte = nuevoArrOfertas;
        }, error => { this.timeoutOfertas( this.timeoutMsg ); });
      } else {
        // console.log('No conectado');
        this.timeoutOfertas(this.tiempoMaximo);
      }
    }, milliseconds);
  }

  timeoutMensajes(milliseconds: number): void  {
    this.interval = setTimeout(() => {
      // console.log('Timeout Mensajes');
      if ( this.loginService.isLogged()) {
        this.loginService.refrescaMensajes().subscribe( totalMensajes => {

          const hayCambios = (this.msgPdte !== totalMensajes);
          if ( hayCambios) {
            console.log('EMIT ----> Nuevo mensaje ' + totalMensajes);
            // Para que no se mueva el scroll arriba cada vez que llega un nuevo mensaje
            // En lugar de emitir el total, emitimos si hay o no nuevos mensajes.
            if ( this.msgPdte == null
                || (this.msgPdte === 0 && totalMensajes > 0)
                || (this.msgPdte > 0 && totalMensajes === 0)) {
              this.totalMsgPendientes.emit(totalMensajes > 0 ? 1 : 0);
            }
          }
          this.timeoutMsg = this.tiempoSiguienteLlamada( hayCambios, this.timeoutMsg );
          this.timeoutMensajes( this.timeoutMsg );
          this.msgPdte = totalMensajes;
        }, error => { this.timeoutMensajes( this.timeoutMsg ); });
      } else {
        // console.log('No conectado');
        this.timeoutMensajes(this.tiempoMaximo);
      }
    }, milliseconds);
  }

  // Calcula el tiempo para realizar la siguiente llamada de refresco
  // Si no hay cambios recientes el tiempo se va alargando hasta el máximo.
  // Si hay cambios recientes el tiempo se acorta al mínimo.
  tiempoSiguienteLlamada( algunCambio: boolean, tiempoActual: number): number {
    if ( algunCambio ) {
      return this.tiempoMinimo;
    } else {
      const aumento = tiempoActual + this.incremento;
      if ( aumento < this.tiempoMaximo) {
        console.log('incr' + aumento);
        return aumento;
      }
    }
    return this.tiempoMaximo;
  }

}
